
import request from '@/utils/request'
import { defineComponent } from 'vue'
import { ElMessage } from 'element-plus'
export default defineComponent({
	data() {
		return {
			user: {
				is_subscribe: 1,
				token: '',
			},
		}
	},
	created() {
		this.getMine()
	},
	methods: {
		async getMine() {
			const { data } = await request.get('/v1/merchants/user').catch((e) => {
				return {
					data: {
						is_subscribe: 1,
					},
				}
			})
			this.user = data
			console.log(data)
		},
		async changeSubscribe(value: any) {
			const data = await request
				.put('/v1/merchants/user/subscribe', {
					is_subscribe: value,
				})
				.catch((e) => {
					return false
				})
			if (!data) return
			ElMessage({
				showClose: true,
				message: '修改成功',
				type: 'success',
			})
		},
	},
})
