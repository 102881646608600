import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_descriptions, {
        class: "margin-top",
        title: "系统设置",
        column: 1,
        size: "medium"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_descriptions_item, { label: "客户编码" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.user.code), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, { label: "授权凭证(Token)" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.user.token ? _ctx.user.token : '暂无'), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, { label: "每天短信提醒：" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tooltip, {
                content: '是否开启: ' + (_ctx.user.is_subscribe == 1 ? '开启' : '关闭'),
                placement: "top"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_switch, {
                    modelValue: _ctx.user.is_subscribe,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.is_subscribe) = $event)),
                    style: {"--el-switch-on-color":"#13ce66","--el-switch-off-color":"#ff4949"},
                    "active-value": 1,
                    "inactive-value": 0,
                    onChange: _ctx.changeSubscribe
                  }, null, 8 /* PROPS */, ["modelValue", "onChange"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["content"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}